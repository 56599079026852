<template>
  <!-- 银行开户管理 -->
  <div class="bankAccountManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
        :operation-width="180"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <!-- <Dialog
      ref="dialog"
      dialogWidth="40%"
      :edit-form-data="editFormData"
      :formItem="addAndEditFormItem"
      @getFormData="getFormData"
    >
      <template slot="dynamicInsert">
        <el-form-item
          label="会员名称"
          prop="cmpId"
          :rules="[{ required: true, message: '请输入选择', trigger: 'change' }]"
        >
          <el-select
            v-model="editFormData.cmpId"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>
    </Dialog> -->
    <!-- 审核弹窗 -->
    <Audit ref="auditDialog" :audit-option-item="auditOptionItem" @reviewSubmitted="reviewSubmitted" />
    <OpenAccount :dialog-visible.sync="editFormVisible" @getData="getdata" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import OpenAccount from './components/OpenAccount'
import Audit from '@/components/Audit'
// import { formPattern } from '@/utils/matchFormRegex'
import { cmpBankinfoPage, cmpBankinfoAudit } from '@/api/accountManagementSystem'
import { getCmpBaseinfoList } from '@/api/memberManagement'
export default {
  components: { FormSearch, Table, Pagination, Audit, OpenAccount },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      auditOptionItem: [{ label: '通过', value: '01' }, { label: '拒绝', value: '-1' }],
      itemData: [
        { label: '会员编码', prop: 'cmpId', width: 120 },
        { label: '会员名称', prop: 'cmpName', width: 180 },
        { label: '结算银行', prop: 'settleBankName', width: 180 },
        { label: '银行席位号', prop: 'bankCmpId', width: 180 },
        { label: '开户行名称', prop: 'openBankName', width: 180 },
        { label: '开户行网点', prop: 'openBankNet', width: 180 },
        { label: '开户账号', prop: 'openAccNo', width: 180 },
        { label: '状态', prop: 'state', width: 100, child: [{ value: '01', label: '有效' }, { value: '02', label: '待平台审核' }, { value: '10', label: '待银行审核' }, { value: '11', label: '银行拒绝' }, { value: '-1', label: '平台拒绝' }] }

      ],
      // addAndEditFormItem: [
      //   { label: '结算银行', type: 'select', value: 'settleBankId', optionLabel: 'dictName', optionId: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') },
      //   {
      //     label: '开户账号', type: 'input', value: 'openAccNo', rules: [
      //       { required: true, message: '请输入开户行账号', trigger: 'blur' },
      //       {...formPattern.isNumber},
      //       { min: 6 , max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
      //     ]
      //   },
      //   { label: '开户行名称', type: 'input', value: 'openBankName' },
      //   { label: '开户行网点', type: 'input', value: 'openBankNet' },
      //   { label: '备注', type: 'input', value: 'remark', textarea: true, rules: 'none' },
      // ],
      operationButton: [
        { num: '02', val: 'state', bType: 'primary', label: '审核', handleEvent: this.enableDisableRow },
        { num: '01', val: 'state', bType: 'success', label: '提现账号', handleEvent: this.viewDetails, withParameters: 1 },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      formItemArr: [
        { type: 'select', label: '结算银行', value: 'settleBankId', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') },
        { type: 'input', label: '会员名称', value: 'cmpName' }
      ],
      loading: false,
      auditId: '',
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      editFormVisible: false
    }
  },
  methods: {
    // 联动搜搜货主列表
    remoteMethod(value) {
      if (!value) return
      getCmpBaseinfoList(value, res => {
        this.cmpNameArr = [...res.data]
      })
    },
    // 点击审核按钮
    reviewSubmitted(value) {
      value.id = this.auditId
      cmpBankinfoAudit(value, res => {
        this.$message.success(res.msg)
        this.$refs.auditDialog.dialogAuditFormVisible = false
        this.getdata(true)
      })
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      cmpBankinfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.editFormData = { ...row } || {}
      // this.$refs.dialog.editFormVisible = true
      this.editFormVisible = true
    },
    // 点击审核按钮
    enableDisableRow(row) {
      this.auditId = row.id
      this.$refs.auditDialog.dialogAuditFormVisible = true
    },
    // 获取到输入框的内容
    // getFormData(value) {
    //   // 获取会员名称
    //   this.cmpNameArr.forEach(item => {
    //     if (item.cmpCode === value.cmpId)
    //       value.cmpName = item.cmpName
    //   })
    //   // 获取结算银行的名称
    //   let arr = this.$store.getters.getDictionaryItem('settleBankName')
    //   arr.forEach(item => {
    //     if (item.dictId === value.settleBankId)
    //       value.settleBankName = item.dictName
    //   })
    //   cmpBankinfoAdd(value, () => {
    //     this.getdata()
    //     this.$message.success('成功！')
    //     this.$refs.dialog.editFormVisible = false
    //   })
    // },
    // 产看详情
    viewDetails(row, withParameters) {
      this.$router.push({
        path: '/accountManagementSystem/bankAccountDetails',
        query: { id: row.id, cmpId: row.cmpId, settleBankId: row.settleBankId, withParameters }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bankAccountManagement {
  .cmpNameSearch {
    width: 100%;
  }
  .marginTop {
    padding-top: 18px;
  }
}
</style>
